import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Markdown from "./Markdown";
import moment from "moment";

import Link from "./localizedLink";

import * as Styles from "../styles/blogLayout.module.scss";

const BlogTile = ({ blogNode, type="default" }) => {
  return (
    <article key={blogNode.id} class={Styles.blogTile}>
      <Link to={`/blog/${blogNode.slug}`} locale={blogNode.frontmatter.locale}>
        <div class={Styles.blogTileInner}>
          <div className={Styles.heroImageBox}>
            <div className={Styles.heroImage}>
              <GatsbyImage
                image={getImage(blogNode.frontmatter.hero_image)}
                imgStyle={{ objectFit: "cover" }}
              />
            </div>
            <div className={Styles.eventDate}>
              {blogNode.frontmatter.eventDate
                ? new moment(blogNode.frontmatter.eventDate).format(
                    "DD.MM.YYYY"
                  )
                : ""}
            </div>
          </div>{" "}
          <div className={Styles.blogTileContent}>
            <h2>{blogNode.frontmatter.title}</h2>

            <div class={Styles.blogMeta}>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><g transform="scale(10.66667, 10.66667)"><path d="M6 1v2h-1c-1.1 0 -2 0.9 -2 2v14c0 1.1 0.9 2 2 2h14c1.1 0 2 -0.9 2 -2v-14c0 -1.1 -0.9 -2 -2 -2h-1v-2h-2v2h-8v-2zM5 8h14v11h-14z"></path></g></svg>
                <span>{blogNode.frontmatter.date}</span>
              </div>
                
                {blogNode.frontmatter.author ? (<div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><g transform="scale(10.66667, 10.66667)"><path d="M12 2c-5.523 0 -10 4.477 -10 10c0 5.523 4.477 10 10 10c5.523 0 10 -4.477 10 -10c0 -5.523 -4.477 -10 -10 -10zM12 4.75c1.795 0 3.25 1.455 3.25 3.25c0 1.795 -1.455 3.25 -3.25 3.25c-1.795 0 -3.25 -1.455 -3.25 -3.25c0 -1.795 1.455 -3.25 3.25 -3.25zM12 20c-2.77 0 -5.21 -1.408 -6.646 -3.547c1.121 -1.63 4.692 -2.453 6.646 -2.453c1.954 0 5.525 0.823 6.646 2.453c-1.436 2.139 -3.876 3.547 -6.646 3.547z"></path></g></svg>
                    <span><Markdown>{blogNode.frontmatter.author}</Markdown></span>
                  </div>) : ("")}

            </div>


            <p class={Styles.blogExcerpt}>{blogNode.excerpt}</p>
            
            <Link to={`/blog/${blogNode.slug}`} locale={blogNode.frontmatter.locale}>{ type == 'career' ? 'Mehr erfahren' : 'Mehr lesen' }</Link>
          </div>
        </div>
      </Link>
    </article>
  );
};
export default BlogTile;
